<template>
<Header />
  <div class="wrap">
    <div class="content">
      <div class="intro">
        <div class="focus intro-title">
          中国杨凌农业高新科技成果博览会云上展会隐私权政策
        </div>
        <div class="date intro-date">发布时间：2021年10月5日</div>
        <div class="focus">提示条款：</div>
        <div class="">
          您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，中国杨凌农业高新科技成果博览会云上展会平台服务提供者（或简称“我们”）特制定本《隐私权政策》（下称“本政策”）以向您说明，在您访问或使用中国杨凌农业高新科技成果博览会云上展会服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息。
        </div>
        <div class="focus">
          本政策适用于我们提供中国杨凌农业高新科技成果博览会云上展会产品及服务，包括中国杨凌农业高新科技成果博览会云上展会平台官网及中国杨凌农业高新科技成果博览会云上展会平台客户端。
        </div>
        <div class="focus">
          需要特别说明的是，本政策不适用于其他第三方通过前述网页或客户端直接向您提供的服务（统称“第三方服务”）。您向该第三方服务提供者提供的信息不适用于本政策，您在选择使用第三服务前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否开通功能。
        </div>
        <div class="focus">
          在使用中国杨凌农业高新科技成果博览会云上展会平台产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。特别是以粗体标识的条款，您应重点约定，并在确认充分理解并同意后再开始使用。
        </div>
      </div>
      <div class="first-para mt">
        <div class="focus">第一部分 定义</div>
        <div class="">
          <span class="focus">中国杨凌农业高新科技成果博览会云上展会平台：</span>指<span
            class="focus"
            >中国杨凌农业高新科技成果博览会云上展会</span
          >PC端及WAP端网站、<span class="focus"
            >中国杨凌农业高新科技成果博览会云上展会</span
          >PC和移动客户端。
        </div>
        <div class="">
          <span class="focus">中国杨凌农业高新科技成果博览会云上展会服务提供者：</span
          >是指<span class="focus">中国杨凌农业高新科技成果博览会云上展会</span
          >的互联网信息及软件服务提供者杨凌农高会筹委会（注册地址：杨凌示范区产业路中段（农高会C馆）展览局)，以下简称“杨凌农高会筹委会”或者<span
            class="focus"
            >“我们”。</span
          >
        </div>
        <div class="">
          <span class="focus">个人信息：</span
          >指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        </div>
        <div class="">
          <span class="focus">个人敏感信息：</span
          >是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本权政策中对具体个人敏感信息以粗体进行显著标识）。
        </div>
        <div class="">
          <span class="focus">个人信息删除：</span
          >指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
        </div>
        <div class=""><span class="focus">儿童：</span>是指不满十四周岁的未成年人。</div>
      </div>
      <div class="second-para mt">
        <div class="focus">第二部分 隐私权政策</div>
        <div class="">本隐私政策部分将帮助您了解以下内容：</div>
        <div class="">一、我们如何收集和使用您的信息</div>
        <div class="">二、我们如何使用Cookie和同类技术</div>
        <div class="">三、我们如何共享、转让、公开披露您的信息</div>
        <div class="">四、我们如何保护您的信息</div>
        <div class="">五、您如何管理您的信息</div>
        <div class="">六、我们如何处理儿童的信息</div>
        <div class="">七、本隐私权政策如何更新</div>
        <div class="">八、如何联系我们</div>

        <div class="mt">
          <div class="focus">一、我们如何收集和使用您的个人信息</div>
          <div class="">
            我们会为实现本政策下述的各项功能，手机和使用您的个人信息：在您使用我们的中国杨凌农业高新科技成果博览会云上展会平台产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
          </div>
          <div class="">
            <span class="focus">1，为实现向您提供</span
            >中国杨凌农业高新科技成果博览会云上展会<span class="focus"
              >服务的基本功能所必须收集的信息。如您拒绝提供此类信息，您将无法正常使用我们的中国杨凌农业高新科技成果博览会云上展会服务；</span
            >
          </div>
          <div class="focus">
            2，为实现向您提供更为便利和丰富的服务，您也可选择授权我们收集、使用您的信息以便向您提供此类服务。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们服务的基本功能。
          </div>
          <div class="">您理解并同意：</div>
          <div class="">
            1，我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同会员选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
          </div>
          <div class="">
            2，为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过中国杨凌农业高新科技成果博览会云上展会平台提供的各种联系方式与我们联系，我们会尽快为您做出解答。
          </div>
          <div class="">
            为了向您及中国杨凌农业高新科技成果博览会云上展会平台会员提供中国杨凌农业高新科技成果博览会云上展会服务，维护中国杨凌农业高新科技成果博览会云上展会平台的正常运营，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用中国杨凌农业高新科技成果博览会云上展会服务时主动提供、授权提供或基于您所在企业组织会员要求提供的信息，以及基于您使用中国杨凌农业高新科技成果博览会云上展会服务时产生的信息：
          </div>
          <div class="focus">（一） 我们对您个人信息的收集</div>
          <div class="focus">1、帮助您成为我们的会员。</div>
          <div class="">
            您须向我们提供姓名、您在中华人民共和国境内手机号码，设置并确认您的登录密码。您提交的手机号码用于您注册、登录、绑定账户、密码找回时接受验证码，并且作为您与中国杨凌农业高新科技成果博览会云上展会指定的联系方式之一，用来接受相关业务通知（如展会预告线、服务通知等）。您还需在账户“基本资料”一栏中填写您的其他信息，包括您的会员身份类型（个人、企业）、企业名称、规模、所属行业、主要产品（服务）、电子邮箱地址、所在地（省份城市）。
          </div>
          <div class="focus">
            2、使用中国杨凌农业高新科技成果博览会云上展会服务过程中收集信息
          </div>
          <div class="">
            在您使用我们服务过程中，为识别账号异常状态、了解产品适配性、帮助主办商优化展会展品展示形式、直播效果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。
          </div>
          <div class="">
            服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
          </div>
          <div class="">
            <span class="focus"
              >请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。</span
            >如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
          </div>
          <div class="focus">
            为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的设备信息和您使用中国杨凌农业高新科技成果博览会云上展会服务时的浏览使用信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。
          </div>
          <div class="">
            我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。
          </div>
          <div class="focus">3、您向我们提供的信息</div>
          <div class="">
            在中国杨凌农业高新科技成果博览会云上展会服务使用过程中，您可以对中国杨凌农业高新科技成果博览会云上展会平台的产品、功能、应用及服务的体验反馈问题，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们的产品或服务,为此我们会记录<span
              class="focus"
              >您的联系信息、反馈的问题或建议</span
            >，以便我们进一步联系您反馈我们的处理意见。
          </div>
          <div class="">
            您可以通过中国杨凌农业高新科技成果博览会云上展会服务为其他人提供认证或订购服务，您需要相关人员的个人信息。如您提交所在企业组织会员进行中国杨凌农业高新科技成果博览会云上展会平台企业组织认证，除提交企业组织会员相关企业名称及工商执照/组织机构代码证等企业文件，还需经办人及<span
              class="focus"
              >法定代表人姓名、身份信息</span
            >。您理解并同意您在向我们提供该实际相关人员前述个人信息之前，您需确保您已经取得其授权同意。若前述个人不同意，我们将不对该个人可能造成的侵权承当相应法律责任。
          </div>
          <div class="focus">4、第三方向我们提供的信息</div>
          <div class="">
            我们将对其他会员进行与您相关的操作时收集关于您的信息，亦可能从中国杨凌农业高新科技成果博览会云上展会平台关联公司、合作第三方或通过其他合法途径获取的您的个人数据信息。例如，为完成您的中国杨凌农业高新科技成果博览会云上展会平台账号注册，您同意运营商基于您个人的手机号码向中国杨凌农业高新科技成果博览会云上展会平台发送<span
              class="focus"
              >短信验证码</span
            >，否则无法注册中国杨凌农业高新科技成果博览会云上展会平台账户。
          </div>
          <div class="focus">5、客户服务</div>
          <div class="">
            当您联系我们的客服时，为了保障您的账户安全，我们需要您提供必要的个人信息以核验您的身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（如账号信息、您为了证明相关事实提供的其他信息，或您留下的<span
              class="focus"
              >电话号码、电子邮箱地址</span
            >等联系方式） 。
          </div>
          <div class="focus">6、其他附加服务</div>
          <div class="">
            为便利采购商与主办商、参展商之间的沟通，企业采购商的员工、联系人或其他代表及个人采购商可在我们平台上通过在线会议、即时通讯、站内信、论坛留言等方式进行洽谈、询盘。如您希望使用这一功能，根据有关实名制的管理要求，您可能需要向我们提供<span
              class="focus"
              >真实姓名、个人身份信息（身份证号码、护照号码等）</span
            >等信息，以便我们进行实名认证。
          </div>
          <div class="">
            对于上述附加服务，您可以选择不提供这些个人信息，但将影响您正常使用中国杨凌农业高新科技成果博览会云上展会服务主要功能。
          </div>
          <div class="focus">7、为您提供安全保障收集信息</div>
          <div class="">
            为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他会员或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或中国杨凌农业高新科技成果博览会云上展会平台相关协议规则的情况，我们在应用程序中嵌入我们关联公司开发的应用安全SDK收集您的<span
              class="focus"
              >个人信息、服务使用信息、设备信息、服务日志信息</span
            >，并可能使用或整合您的会员信息、交易信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
          </div>
          <div class="">
            为避免歧义，上述情形与我们所收集的个人信息的种类并非一一对应，在上述某一情形中所收集的个人信息，也可能在上述其他情形中被收集。
          </div>
          <div class="focus">（二）我们对您的个人信息的使用</div>
          <div class="">
            我们使用上述个人信息和其他类似的信息来为您使用中国杨凌农业高新科技成果博览会云上展会服务提供便利，并会为您提供中国杨凌农业高新科技成果博览会云上展会公司举办的其他展览会的信息及服务。当我们要将个人信息用于本政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集的个人信息用于其他目的时，会事先征求您的同意。
          </div>
          <div class="">
            如我们停止运营中国杨凌农业高新科技成果博览会云上展会平台产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以提前30天以公告的形式通知您，<span
              class="focus"
              >并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理</span
            >。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。
          </div>
        </div>
        <div class="mt">
          <div class="focus">二、我们如何使用 Cookie 和同类技术</div>
          <div class="">
            为使您获得更轻松的访问体验，您使用中国杨凌农业高新科技成果博览会云上展会服务时，我们可能会通过采用各种技术收集和存储您访问中国杨凌农业高新科技成果博览会云上展会服务的相关数据，在您访问或再次访问中国杨凌农业高新科技成果博览会云上展会服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
            Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
          </div>
          <div class="">
            请您理解，我们的某些服务只能通过使用Cookie才可得到实现。<span class="focus"
              >如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝中国杨凌农业高新科技成果博览会云上展会平台的Cookie</span
            >，但拒绝中国杨凌农业高新科技成果博览会云上展会平台的Cookie在某些情况下您可能无法使用依赖于cookies的中国杨凌农业高新科技成果博览会云上展会平台服务的部分功能。
          </div>
        </div>
        <div class="mt">
          <div class="focus">三、我们如何共享、转让、公开披露您的信息</div>
          <div class="focus">(一) 共享</div>
          <div class="">
            我们不会与中国杨凌农业高新科技成果博览会云上展会服务提供方以外的公司、组织和个人共享您的个人信息，但以下情况除外：
          </div>
          <div class="">
            1、在获取您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
          </div>
          <div class="">
            2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
          </div>
          <div class="">
            3、您主动选择情况下的共享：只有共享您的个人信息、个人敏感信息，才能提供您所要求的第三方产品和服务，如：
          </div>
          <div class="">
            4、侵权投诉处置：在您被他人投诉侵犯知识产权或其他合法权利时，需要向投诉人披露您的必要资料，以便进行投诉处理；
          </div>
          <div class="">
            5、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的，除非另行获得您的明示同意除外。对于涉及儿童个人信息的，我们不允许合作伙伴进行转委托。
          </div>
          <div class="">
            1）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术合作研发服务、提供中国杨凌农业高新科技成果博览会云上展会平台软件推广部署服务、提供客户服务、支付便利以及其他合作场景。
          </div>
          <div class="focus">(二) 转让</div>
          <div class="">
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </div>
          <div class="">
            1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
          </div>
          <div class="">
            2、在中国杨凌农业高新科技成果博览会云上展会平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          </div>
          <div class="focus">(三) 公开披露</div>
          <div class="">我们仅会在以下情况下，公开披露您的个人信息：</div>
          <div class="">
            1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
          </div>
          <div class="focus">
            2、如果我们确定您出现违反法律法规或严重违反中国杨凌农业高新科技成果博览会云上展会平台相关协议规则的情况，或为保护中国杨凌农业高新科技成果博览会云上展会平台及其关联公司会员或公众的人身财产安全免遭侵害，我们可能依据法律法规或中国杨凌农业高新科技成果博览会云上展会平台相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及中国杨凌农业高新科技成果博览会云上展会平台已对您采取的措施。
          </div>
          <div class="focus">
            (四) 共享、转让、公开披露个人信息时事先征得授权同意的例外
          </div>
          <div class="">
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </div>
          <div class="">1、与国家安全、国防安全有关的；</div>
          <div class="">2、与公共安全、公共卫生、重大公共利益有关的；</div>
          <div class="">3、与犯罪侦查、起诉、审判和判决执行等有关的；</div>
          <div class="">
            4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </div>
          <div class="">5、您自行向社会公众公开的个人信息；</div>
          <div class="">
            6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          </div>
          <div class="">
            请您注意，根据法律规定，<span class="focus"
              >共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span
            >
          </div>
        </div>
      </div>
      <div class="forth-para mt">
        <div class="focus">四、我们如何保护您的信息</div>
        <div class="focus">（一）技术安全防护</div>
        <div class="">
          为保障您的信息安全，我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的中国杨凌农业高新科技成果博览会云上展会平台客户端APP与服务器之间交换数据时受SSL协议加密保护；我们同时对中国杨凌农业高新科技成果博览会云上展会平台官网提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </div>
        <div class="">
          我们指定了专人负责儿童信息的保护，严格设定信息访问权限，对可能接触到儿童信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。
        </div>
        <div class="focus">（二）人员安全管理</div>
        <div class="">
          我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我方会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我方将尽力做到使您的信息不被泄漏、毁损或丢失。我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </div>
        <div class="focus">（三）存储安全</div>
        <div class="">
          <span class="focus"
            >我们会采取合理可行的措施存储您的个人信息，尽力避免收集无关的个人信息。我们只会在在限于达成本政策所述目的所需的期限以及所适用法律法规所要求的期限内保留您的个人信息。</span
          >例如，中国杨凌农业高新科技成果博览会云上展会平台将依据《中华人民共和国电子商务法》的要求，就您在中国杨凌农业高新科技成果博览会云上展会平台购买的第三方收费型服务应用及应用内付费型服务的交易商品、服务信息、交易信息，保存时间自交易完成之日起不少于三年。
          中国杨凌农业高新科技成果博览会云上展会平台将依据《中华人民共和国网络安全法》的要求，就您使用中国杨凌农业高新科技成果博览会云上展会平台应用服务留存相关的网络日志不少于六个月。而我们判断前述期限的标准包括：
        </div>
        <div class="">
          1、完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；
        </div>
        <div class="">2、保证我们为您提供服务的安全和质量；</div>
        <div class="">3、您是否同意更长的留存期间；</div>
        <div class="">4、是否存在保留期限的其他特别约定。</div>
        <div class="focus">
          在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
        </div>
        <div class="focus">（四）信息安全事件处理</div>
        <div class="">
          发生个人信息安全事件后，我们将按照法律法规的要求（最迟不迟于30
          个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
        </div>
        <div class="focus">（五）儿童个人信息安全</div>
        <div class="">
          我们已制定儿童信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将承担相应的法律责任。
        </div>
        <div class="focus">
          互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将基于正常的商业努力确保您的信息的安全。
        </div>
      </div>
      <div class="fifth-para mt">
        <div class="focus">五、您如何管理您的信息</div>
        <div class="">您可以通过以下方式访问及管理您的信息：</div>
        <div class="focus">（一）查询、更正和补充您的信息</div>
        <div class="">
          除法律法规另有规定外，您有权随时登录您的账户，自行访问您的个人信息。当您发现我们处理的您的个人信息有错误时，您有权要求我们做出更正。您可以自行登录您的账户，对您的个人信息进行修改更正。
        </div>
        <div class="">
          您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。
        </div>
        <div class="focus">（二）删除您的信息</div>
        <div class="">
          您可以通过上述“（一）查询、更正和补充您的信息”中列明的方式删除您的部分信息。
        </div>
        <div class="">在以下情形中，您可以向我们提出删除个人信息的请求：</div>
        <div class="">1、如果我们处理个人信息的行为违反法律法规；</div>
        <div class="">2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</div>
        <div class="">3、如果我们处理个人信息的行为严重违反了与您的约定；</div>
        <div class="">4、如果您不再使用我们的产品或服务，或您主动注销了账号；</div>
        <div class="">5、如果我们永久不再为您提供产品或服务。</div>
        <div class="">
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
        </div>
        <div class="">
          当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
        </div>
        <div class="focus">（三）改变您授权同意的范围或撤回您的授权</div>
        <div class="">
          在您授权我们收集和处理您的个人信息的范围内，您可以通过更改设置等方式改变您授权我们处理您信息的范围或撤回您的授权。您可以通过更改您的客户端设备中的设置来进行退订。您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。
        </div>
        <div class="">
          请您理解，每个业务功能需要一些必要的信息才能得以完成，当您撤回同意或授权后，我们将无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的信息处理。
        </div>
        <div class="focus">（四）注销您的账户</div>
        <div class="">您可以自行登录您的账户，并对其进行注销。</div>
        <div class="">
          您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。
        </div>
        <div class="">（五）约束信息系统自动决策</div>
        <div class="">
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害中国杨凌农业高新科技成果博览会云上展会平台商业秘密或其他会员权益、社会公共利益的前提下提供申诉方法。
        </div>
        <div class="focus">（六）响应您的上述请求</div>
        <div class="">
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        </div>
        <div class="">
          我们将在30天内做出答复。如您不满意，还可以通过中国杨凌农业高新科技成果博览会云上展会平台客服发起投诉。
        </div>
        <div class="">
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
        </div>
        <div class="">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
        <div class="">1、与国家安全、国防安全有关的；</div>
        <div class="">2、与公共安全、公共卫生、重大公共利益有关的；</div>
        <div class="">3、与犯罪侦查、起诉、审判和执行判决等有关的；</div>
        <div class="">4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</div>
        <div class="">
          5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
        </div>
        <div class="">
          6、涉及中国杨凌农业高新科技成果博览会云上展会平台公司和/或中国杨凌农业高新科技成果博览会云上展会平台企业组织会员商业秘密的。
        </div>
      </div>
      <div class="sixth-para mt">
        <div class="focus">六、未成年人保护</div>
        <div class="">
          我们的平台、产品与/或服务主要面向企业和成人。如果没有父母或监护人的同意，儿童不得创建自己的会员账户或作为会员联系人。如您为儿童，我们要求您请您的父母或其他监护人仔细阅读本隐私权政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。
        </div>
        <div class="">
          对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
        </div>
        <div class="focus">
          若您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务。如您对您所监护的儿童的个人信息有疑问，请通过第九节中的联系方式与我们联系。
        </div>
      </div>
      <div class="seventh-para mt">
        <div class="focus">七、本政策如何更新</div>
        <div class="">我们的隐私政策可能变更。</div>
        <div class="">
          未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。我们会在中国杨凌农业高新科技成果博览会云上展会平台官方主页上公告发布对本隐私政策所做的任何变更。
        </div>
        <div class="">
          对于重大变更，我们还会提供更为显著的通知（包括我们会通过中国杨凌农业高新科技成果博览会云上展会平台官方网站公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
        </div>
        <div class="">本政策所指的重大变更包括但不限于：</div>
        <div class="">
          1、我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；
        </div>
        <div class="">
          2、我们在控制权、组织架构等方面发生重大变化。如并购重组等引起的控制者变更等；
        </div>
        <div class="">3、个人信息共享、转让或公开披露的主要对象发生变化；</div>
        <div class="">4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
        <div class="">
          5、我们负责处理会员信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </div>
        <div class="">6、会员信息安全影响评估报告表明存在高风险时。</div>
      </div>
      <div class="eighth-para mt">
        <div class="focus">八、如何联系我们</div>
        <div class="">
          如果您对中国杨凌农业高新科技成果博览会云上展会平台的隐私保护政策或数据处理有任何疑问、意见或建议，可以通过以下方式联系我们：
        </div>
        <div class="focus">杨凌农高会筹委会联系方式</div>
        <div class="">
          农高会参展招商热线：+86(0)29-87036991、87036992、87036419、87031012
          传真：+86(0)29-87036995
        </div>
        <div class="">
          农业机械工程展区咨询热线：+86(0)29-87033933 传真：+86(0)29-87038560
        </div>
        <div class="">
          农高会专业观众咨询热线：+86(0)29-87035894 传真：+86(0)29-87036994。
        </div>
        <div class="">办公地址：杨凌示范区产业路中段农高会C馆</div>
        <div class="">邮政编码：712100</div>
        <div class="">联系电话：029-87036998</div>
        <div class="">
          一般情况下，我们将在收到您相关联系信息并核实您身份后十五个工作日内回复。
        </div>
        <div class="">
          如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，<span
            class="focus"
            >您还可以通过向上海国际经济贸易仲裁委员会提起仲裁来寻求解决方案。</span
          >
        </div>
      </div>
    </div>
    <u-back-top :scroll-top="scrollTop"></u-back-top>
  </div>
</template>

<script>
// import uaNavbar from "../../../components/ua-navbar.vue";
import Header from '../../components/Header'
export default {
  data() {
    return {
      scrollTop: 0,
    };
  },
  components: {
	  Header
  }
  // onPageScroll(e) {
  // 	this.scrollTop = e.scrollTop;
  // },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 50px 80px;
  width: 100%;
  .content {
	  margin: 0 auto;
    max-width: 1240px;
    min-width: 1240px;
    line-height: 30px;
    .intro {
      .intro-title {
        text-align: center;
      }
      .intro-date {
        text-align: right;
      }
    }
    line-height: 50rpx;
    padding: 40rpx 20rpx;
    .mt {
      margin-top: 50rpx;
    }
    .focus {
      font-weight: 700;
    }
  }
}
</style>
